/* 3rd party imports */
import { Theme } from 'theme-ui';

export default {
	fonts: {
		heading: '"Open Sans", "Noto Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"',
		body: '"Ubuntu", "Helvetica", "Arial", "sans-serif"',
	},
	fontSizes: [
		12, 14, 16, 20, 24, 32, 48, 64,
	],
	fontWeights: {
		body: 400,
		semibold: 500,
		bold: 700,
	},
	text: {
		caps: {
			textTransform: 'uppercase',
			letterSpacing: '0.2em',
		},
		heading: {
			fontFamily: 'heading',
			fontWeight: 'heading',
			lineHeight: 'heading',
		},
	},
} as Theme;