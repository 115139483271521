/* 3rd party imports */
import { Theme } from 'theme-ui';

export default {
	regular: {
		color: 'link',
		textDecoration: 'none',
		':hover': {
			color: 'linkHover',
		},
	},
	nav: {
		color: 'layoutLink',
		textDecoration: 'none',
		':hover': {
			color: 'layoutLinkHover',
		},
	},
} as Theme['links'];