// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-templates-index-page-index-tsx": () => import("./../../../src/templates/IndexPage/index.tsx" /* webpackChunkName: "component---src-templates-index-page-index-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/Post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-posts-page-index-tsx": () => import("./../../../src/templates/PostsPage/index.tsx" /* webpackChunkName: "component---src-templates-posts-page-index-tsx" */)
}

